<script>
import { ValidationObserver } from 'vee-validate'
import { ref, computed, onMounted } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseMetafield from '/~/components/base/metafield/base-metafield.vue'
import { useAddressForm, useAddresses } from '/~/composables/addresses'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

const defaultData = {
  companyName: '',
  default: false,
  email: '',
  firstName: '',
  id: -1,
  lastName: '',
  mobile: '',
  postcode: '',
  state: '',
  streetAddress: '',
  subpremise: '',
  suburb: '',
  fullAddress: '',
}

export default {
  name: 'drawer-add-address',
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseAsidePage,
    BaseMetafield,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const { providerCountry } = useProvider()
    const { addresses, addAddress, updateAddress } = useAddresses()
    const { addressSchema } = useAddressForm()
    const { user } = useUser()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { states, translate } = useLocalization()

    const addressForm = ref(null)
    const addressFormPreset = ref(null)

    const address = computed(() => {
      return (
        (addresses.value || []).find(
          (address) => `${address.id}` === props.id
        ) || {
          ...defaultData,
        }
      )
    })

    const isTypeCreate = computed(() => {
      return address.value.id === -1
    })

    onMounted(() => {
      if (isTypeCreate.value) {
        addressFormPreset.value?.validate()
      } else {
        addressForm.value?.validate()
      }
    })

    return {
      addresses,
      addAddress,
      updateAddress,
      user,
      backendErrors,
      processBackendErrors,
      addressForm,
      addressFormPreset,
      address,
      isTypeCreate,
      states,
      addressSchema,
      providerCountry,
      translate,
    }
  },
  data() {
    return {
      form: {
        ...defaultData,
      },
      submitting: false,
      initialData: {},
      buttonShadow: false,
    }
  },
  computed: {
    addressValue() {
      return {
        address: {
          fullAddress: this.form.fullAddress,
          address: this.form.streetAddress,
          suburb: this.form.suburb,
          postcode: this.form.postcode,
          state: this.form.state,
        },
      }
    },
    stateOptions() {
      return this.states.map((state) => ({
        text: state.label,
        value: state.label,
      }))
    },
    selectedStateId() {
      const state = this.states.find((state) => {
        return state.label === this.form.state
      })

      return state ? state.id : ''
    },
    disableSaveBtn() {
      return this.submitting
    },
  },
  created() {
    const { email, firstName, lastName, mobile } = this.user
    const userDetails = this.isTypeCreate
      ? { email, firstName, lastName, mobile }
      : {}

    const { streetAddress, suburb, state, postcode } = this.address

    const form = {
      ...this.form,
      ...this.address,
      state: state,
      fullAddress: Object.values({ streetAddress, suburb, state, postcode })
        .filter(Boolean)
        .join(' '),
      ...userDetails,
    }

    this.form = form
    this.initialData = { ...form }
  },
  mounted() {
    this.checkShadow()
  },
  methods: {
    onAddressChanged(value) {
      Object.assign(this.form, value.address)
      this.form.streetAddress = value.address.address
    },
    async onSubmitForm() {
      this.submitting = true
      this.backendErrors = {}

      const action = this.isTypeCreate ? this.addAddress : this.updateAddress
      const params = {
        ...this.form,
        mobile: (this.form.mobile || '')
          .replace(/\s/g, '')
          .replace(/^\+61/g, '0'),
        state: this.selectedStateId || this.form.state,
      }

      return action(params)
        .then(() => {
          this.submitting = false

          this.$notify({
            text: `Address successfully ${
              this.isTypeCreate ? 'created' : 'updated'
            }`,
            type: 'success',
            duration: 1000,
          })

          this.$router.back()
        })
        .catch((exception) => {
          this.submitting = false
          this.processBackendErrors({
            errors: exception,
          })
        })
    },
    checkShadow() {
      const { form } = this.$refs

      if (!form) {
        return false
      }

      const scrollAmount = form.scrollHeight - form.offsetHeight

      if (scrollAmount && scrollAmount !== form.scrollTop) {
        this.buttonShadow = true
      } else {
        this.buttonShadow = false
      }
    },
  },
}
</script>

<template>
  <base-aside-page :title="isTypeCreate ? 'Add address' : 'Edit address'">
    <validation-observer
      ref="addressForm"
      v-slot="{ valid }"
      tag="form"
      class="relative flex h-full flex-col"
      @submit.prevent="onSubmitForm"
    >
      <div class="relative grow overflow-y-auto" @scroll="checkShadow">
        <base-input
          v-model="form.state"
          label="State"
          required
          class="hidden"
        />
        <validation-observer ref="addressFormPreset">
          <base-input
            v-model="form.companyName"
            :error="backendErrors.companyName"
            label="Company name"
            placeholder="Optional"
            name="companyName"
            :disabled="submitting"
          />
          <base-input
            v-model="form.firstName"
            :validation="{
              rules: 'required',
              name: 'First name',
            }"
            :disabled="submitting"
            :error="backendErrors.firstName"
            label="First name"
            required
            name="firstName"
          />
          <base-input
            v-model="form.lastName"
            :validation="{
              rules: 'required',
              name: 'Last name',
            }"
            :disabled="submitting"
            :error="backendErrors.lastName"
            label="Last name"
            required
            name="lastName"
          />
          <base-input
            ref="email"
            v-model="form.email"
            :validation="{
              rules: 'required|email',
              name: 'Email',
            }"
            :disabled="submitting"
            :error="backendErrors.email"
            label="Email"
            required
            name="email"
            type="email"
          />
          <base-input
            v-model="form.mobile"
            :validation="{
              rules: 'required|mobile',
              name: 'Mobile',
            }"
            :disabled="submitting"
            :error="backendErrors.mobile"
            type="tel"
            mask="mobile"
            label="Mobile"
            required
            name="mobile"
          />
        </validation-observer>

        <div>
          <base-metafield
            :value="addressValue"
            :schema="addressSchema"
            :backend-errors="backendErrors"
            name-prefix="address"
            @input="onAddressChanged"
          />
        </div>

        <base-checkbox
          v-model="form.default"
          :disabled="submitting"
          class="p-0"
          look="v3"
        >
          Set as default
        </base-checkbox>
      </div>

      <div class="relative shrink-0 pt-5">
        <base-button
          type="submit"
          :disabled="submitting || disableSaveBtn || !valid"
          full-width
        >
          {{ submitting ? 'Saving...' : 'Save' }}
        </base-button>
      </div>
    </validation-observer>
  </base-aside-page>
</template>
